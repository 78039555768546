<template>
    <div class="modal-purchase-product">
        <div class="textbox">
            <div class="title f-24 p-t-36 m-l-20 m-r-20 m-b-24">
                프로그램 선택
                <i class="material-icons i-right f-30" @click="onClose()">close</i>
            </div>
            <div class="item-list-container">
                <div
                    class="item-list"
                    v-for="item in productList"
                    :key="item.id"
                    :class="item.code"
                    @click="onClickItem(item)"
                >
                    <div
                        class="product-item"
                        :class="[gender, { selected: item.$$selected }, { package: item.ptype === 'package_plan' }]"
                        :gender="gender"
                    >
                        <div class="image-tag" :class="gender" v-if="item.code.includes('package')">
                            <img class="img-detail" :src="imageTag()" alt="" />
                        </div>
                        <div class="item-content">
                            <div
                                v-if="item.code.includes('prepaid')"
                                class="name f-17 m-t-28"
                                :class="gender"
                                v-html="$translate(getItemName(item.code).toUpperCase())"
                            />
                            <div
                                v-else
                                class="name f-15 m-t-28"
                                :class="gender"
                                v-html="$translate(getItemName(item.code).toUpperCase())"
                            />
                            <div class="price-description f-7">
                                <div class="pre-price">정가</div>
                                <div class="result-price m-t-6">할인가</div>
                            </div>
                            <div class="prices">
                                <div class="pre-price">
                                    <span class="price f-12">
                                        {{ Number(item.original_price * (10 / 11)).toLocaleString() }}
                                    </span>
                                </div>
                                <div class="result-price">
                                    <span class="price f-16 m-t-4">
                                        {{ Number(item.selling_price * (10 / 11)).toLocaleString() }}원
                                    </span>
                                    <span class="discount-rate f-7"> ({{ discountRate(item) }}) </span>
                                </div>
                            </div>
                        </div>
                        <div class="check-circle m-r-20">
                            <img :src="checkboxImg(item)" alt="" height="24px" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tax-description m-t-5 m-b-5 f-12 m-l-20">*모든 상품은 부가세 별도입니다.</div>
            <div class="bottom-button">
                <button class="btn-send-reason f-18" :class="{ disabled: disabled }" @click="onClickPurchase()">
                    멤버십 결제하기
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// import PurchaseProductItem from '@/routes/prepay/components/PurchaseProductItem'
import inquiryService from '@/services/inquiry'
export default {
    name: 'ModalPurchaseMembership',
    props: ['options'],
    // components: { PurchaseProductItem },
    data: () => ({
        disabled: null,
        selectedValue: null,
    }),
    watch: {
        selectedValue() {
            if (this.selectedValue) {
                this.selectedValue.$$selected = true
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.selectedValue.$$selected = false
                this.disabled = true
                this.$emit('disabled', true)
            }
        },
    },
    computed: {
        productList() {
            // const productList = this.options.products.map(v => ({ ...v, $$selected: false }))
            const productList = this.options.products
                .filter(v => v.code.includes('mf_3month') || v.code.includes('mfplus_3month'))
                .map(v => ({ ...v, $$selected: false }))
            // const item = productList.find(o => o.code.includes('package'))
            // item.$$selected = true
            return productList
        },
        gender() {
            if (this.$store.getters.me.gender === 0) {
                return 'male'
            } else {
                return 'female'
            }
        },
    },
    mounted() {
        const item = this.productList.find(o => o.code.includes('mf_3month') || o.code.includes('mfplus_3month'))
        item.$$selected = true
        this.selectedValue = item
        this.disabled = false
    },
    methods: {
        getItemName(code) {
            if (code.includes('mf_3month')) {
                return 'mf_3month'
            } else if (code.includes('mfplus_3month')) {
                return 'mfplus_3month'
            } else {
                return null
            }
        },
        pricePerAmount(item) {
            console.log(item)
            const price = item.selling_price * (10 / 11)
            let amount = 0
            if (item.code.includes('prepaid') || item.ptype === 'ticket_plan') {
                amount = item.amount
            } else if (item.code.includes('unlimited')) {
                amount = item.valid_date / 30
            }
            return Math.round(price / amount)
        },
        discountRate(item) {
            return Math.round((item.discount / (item.original_price * (10 / 11))) * 100) + '% 할인'
        },
        checkboxImg(item) {
            if (item.$$selected) {
                if (this.gender === 'male') {
                    return require(`@/assets/images/icons/purchase_checked_male.png`)
                } else {
                    return require(`@/assets/images/icons/purchase_checked_female.png`)
                }
            } else {
                if (this.gender === 'male') {
                    return require(`@/assets/images/icons/purchase_male.png`)
                } else {
                    return require(`@/assets/images/icons/purchase_female.png`)
                }
            }
        },
        imageTag() {
            return require(`@/assets/images/icons/popular_price.png`)
        },
        onClickItem(item) {
            console.log(item)
            console.log('selected', item.$$selected)
            this.$set(item, '$$selected', !item.$$selected)
            setTimeout(() => {
                if (this.selectedValue && this.selectedValue === item) {
                    this.selectedValue = null
                    this.disabled = true
                    this.$emit('disabled', true)
                } else {
                    this.selectedValue = item
                }
                // this.$emit('disabled', false)
                this.productList.forEach(o => this.$set(o, '$$selected', false))
            }, 200)
            this.searchValue = ''
        },
        async onClickPurchase() {
            try {
                if (this.disabled) {
                    this.$modal.basic({
                        body: '구매할 상품을 선택해주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    // console.log('dddd', this.selectedValue)
                    // console.log('dddd', this.productList)
                    this.$store.commit('setSelectedProduct', this.selectedValue)
                    this.$store.commit('setSelectedPlan', this.selectedValue)
                    const payload = {
                        user_id: this.$store.getters.me.id,
                        category: 'membership',
                        funnel: 'enter_payment',
                    }
                    await inquiryService.updateFunnel(payload)

                    this.$router.push({ name: 'PaymentMethodsPage', params: { categoryName: this.selectedValue } })

                    this.$emit('close')
                }
            } catch (e) {
                this.$toast.error(e)
                console.log(e)
            }
        },
        onClose() {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.modal-purchase-product {
    // @include center;
    .title {
        position: relative;
        font-weight: 600;

        i {
            position: absolute;
            right: 0;
        }
    }
    .product-item {
        position: relative;
        display: flex;
        margin: 12px 20px;
        background: #ffffff;
        height: fit-content;
        border-radius: 8px;

        &.male {
            &.selected {
                background: #cfd3ed;
            }
            border: 1px solid #ced8ff;
            &.package {
                border: 1px solid #a8b9ff;
            }
        }

        &.female {
            &.selected {
                background: #ffe9e9;
            }
            border: 1px solid #ffc2c2;
            &.package {
                border: 1px solid #ffaaaa;
            }
        }

        .image-tag {
            &.female {
                background: #ffaaaa;
            }

            &.male {
                background: #a8b9ff;
            }
            position: absolute;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 8px;
            width: 45px;
            height: 19px;
            @include center;

            .img-detail {
                width: 30px;
            }
        }

        .item-content {
            margin: 0 10px 0 20px;
            margin-right: 50px;
            .name {
                font-weight: 700;

                &.male {
                    color: #2143d4;
                }

                &.female {
                    color: #ff8c8c;
                }
            }

            .per-price {
                color: #030068;
                line-height: 12px;
                font-weight: 500;

                .price-per-amount {
                    font-weight: 600;
                }
            }
            .price-description {
                // margin: 0 20px;
                // position: absolute;
                // right: 0;
                margin-right: 10px;
                text-align: left;
                color: #5c5c77;
                display: inline-block;
                align-self: flex-end;
                padding-bottom: 20px;
                .pre-price {
                    align-items: center;
                    color: #8d8da0;
                    .price {
                        font-weight: 400;
                    }
                }

                .result-price {
                    color: #5c5c77;
                    .price {
                        font-weight: 400;
                    }
                }
            }

            .prices {
                // margin: 0 20px;
                // position: absolute;
                // right: 0;
                text-align: left;
                color: #5c5c77;
                display: inline-block;
                align-self: flex-end;
                padding-bottom: 16px;

                .discount-rate {
                    color: #181f39;
                    font-size: 7px;
                    margin: 4px 0;
                    font-weight: 400;
                }

                .pre-price {
                    align-items: center;
                    color: #8d8da0;
                    .price {
                        font-weight: 400;
                        text-decoration: line-through;
                    }
                }

                .result-price {
                    color: #5c5c77;
                    .price {
                        font-weight: 600;
                    }
                }
            }
        }

        .check-circle {
            height: 100%;
            position: absolute;
            right: 0;
            @include center;
        }
    }
    .tax-description {
        font-weight: 400;
        color: #a9a9a9;
    }
    .bottom-button {
        padding: 12px 20px 20px 20px;
        // position: fixed;
        .btn-send-reason {
            height: 48px;
            width: 100%;
            border: none;
            border-radius: 36px;
            background: #151360;
            color: white;

            &.disabled {
                background: #d7d5d1;
            }
        }
    }
}
</style>
